<template>
  <div class="notification">
    <b-dropdown
      id="dropdown-notification"
      ref="dropdown-notification"
      right
      variant="none"
      no-caret
      class="mt-1"
    >
      <template #button-content>
        <div class="bell-notification" @click="markAllAsHoverdMethod()">
          <font-awesome-icon :icon="['far', 'bell']" class="fa-2x bell1" />
          <font-awesome-icon :icon="['fas', 'bell']" class="fa-2x bell2" />
        </div>

        <div
          class="notification-circle"
          v-if="getCountNotification && getCountNotification > 0"
        >
          {{ getCountNotification }}
        </div>
      </template>

      <div class="notification-option">
        <span class="not-style">Notifications</span>
        <!-- <div class="Plus" @click="hideDropdown">
          <router-link to="/all-notification">Voir tout</router-link>
        </div> -->
      </div>
      <hr class="hr-style" />
      <LoadingComponent v-if="getNotificationsLoader" />
      <LoadingComponent v-if="getNotificationsLoader" />
      <div v-else class="box-dropdown">
        <div
          class="dropdown-item-list"
          v-for="(not, index) in getAllNotifications"
          :key="'notification' + not.id + index"
          @click="showdetailNotification(not)"
        >
          <div class="content-item">
            <div class="icon-type" :class="{ active: not.seen == false }">
              <font-awesome-icon :icon="not.getIconName()" />
            </div>
            <div class="body-item">
              <div>
                <span v-html="not.getMessageNotification()"> </span>
              </div>
              <div class="time" :class="{ active: not.seen == false }">
                <!-- il y a -->
                {{ not.created_at }}
              </div>
            </div>
            <div class="icon-close" @click.prevent.stop="handleDeleteNot(not)">
              <div
                class="chargement chargement-loading-icon"
                v-if="not.loaderDelet"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <font-awesome-icon :icon="'times'" v-else />
            </div>
          </div>
        </div>
      </div>
      <div class="voir-plus" v-if="computedvoirPlus">
        <span class="title" @click.prevent.stop="hendelClickMoreNotif"
          >Voir plus</span
        >
      </div>

      <div
        class="notification-vide"
        v-if="getAllNotifications.length == 0 && !getNotificationsLoader"
      >
        aucun notification
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import LoadingComponent from '@/views/component/LoadingComponent';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return { page: 1 };
  },
  methods: {
    ...mapActions([
      'fetchAllNotifications',
      'updateNotification',
      'markAllAsHoverdAction',
      'fetchNewNotification',
      'deleteNot'
    ]),
    hideDropdown() {
      if (this.$refs['dropdown-notification']) {
        this.$refs['dropdown-notification'].hide(true);
      }
    },
    showdetailNotification(not) {
      this.hideDropdown();
      if (not.seen == false) {
        this.updateNotification(not);
      }
      not.showdetailsNotification();
    },
    markAllAsHoverdMethod() {
      if (this.getCountNotification > 0) {
        this.markAllAsHoverdAction({
          ids: this.getAllNotifications.map(i => i.id)
        });
      }
    },
    hendelClickMoreNotif() {
      this.page = this.page + 1;
      this.fetchNewNotification({ page: this.page });
    },
    handleFilter() {
      this.fetchAllNotifications({ page: this.page });
    },
    async handleDeleteNot(not) {
      not.loaderDelet = true;
      await this.deleteNot(not);
      not.loaderDelet = false;
    }
  },
  mounted() {
    this.handleFilter();
  },
  computed: {
    ...mapGetters([
      'getAllNotifications',
      'getCountNotification',
      'getNoutificationToShow',
      'getNotificationsLoader',
      'gettotalPageNotification'
    ]),
    computedvoirPlus() {
      if (this.gettotalPageNotification / 10 > this.page) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    LoadingComponent
  }
};
</script>

<style lang="scss">
.svg-inline--fa.fa-w-10 {
  height: 25px;
}
.notification {
  .dropdown-menu {
    padding: 5px;
    width: 260px;
    background-color: #ffffff;
    overflow-y: hidden;
    // max-height: 424px;
    // height: 424px;
    border: none;
    border-radius: 16px;
    box-shadow: 0 0 5px #ced0d4;
  }
  .notification-option {
    display: flex;
    margin: 4px 10px 5px 10px;
    justify-content: space-around;
    .not-style {
      color: #5d5d5d;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }
    .Plus {
      color: #4d4bac;
      font-size: 12px;
      text-align: end;
      font-weight: 600;
      width: 50%;
      margin: auto;
      a {
        color: #4d4bac;
      }
      .title {
        color: #4d4bac;
      }
    }
  }
  .voir-plus {
    color: #4d4bac;
    font-size: 12px;
    text-align: end;
    font-weight: 600;
    margin: auto;
    text-align: center;
    .title {
      color: #4d4bac;
    }
  }
  .hr-style {
    color: #d9d9d9;
    margin: 2px;
  }
  .notification-vide {
    font-size: 14px;
    align-items: center;
    text-align: center;
    margin: 15px;
    font-weight: 400;
    color: #5d5d5d;
  }

  .active {
    .dropdown-item-list {
      background-color: #2dabe2c4;
      border-radius: 0.125rem;
      cursor: pointer;
    }
  }
  .box-dropdown {
    overflow-y: auto;
    max-height: 365px;
    // height: 365px;
  }
  .box-dropdown::-webkit-scrollbar {
    width: 10px;
  }

  .box-dropdown::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .box-dropdown::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }
  .box-dropdown::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
  .dropdown-item-list {
    padding: 0.5rem;
    margin-left: 0;
    font-size: 0.8rem;
    color: #000;
    margin-bottom: 5px;
    .content-item {
      display: flex;
      cursor: pointer;
      .icon-type {
        min-width: 38px;
        max-width: 38px;
        font-size: 24px;
        padding-top: 8px;
      }
      .body-item {
        display: inline;
        font-size: 11px;
        width: 85%;
      }
      .icon-close {
        width: 19px;
        height: 19px;
        font-size: 10px;
        text-align: center;
        color: #5f6368;
        &:hover {
          background: #dee0e1;
          border-radius: 50px;
        }
        .chargement-loading-icon .spinner-border {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
    .time {
      font-size: 80%;
      font-weight: 600;
    }
    .active {
      color: #8d8cb7;
    }
  }
  .notification-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ff3447;
    font-size: 10px;
    top: -32px;
    right: -15px;
    color: #fff;
    position: relative;
    z-index: 10;
  }
  .bell-notification {
    max-width: 65px;
    min-width: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    color: #5d5d5d;
    &:hover,
    &:focus {
      .bell1 {
        z-index: 0;
      }
    }
    .bell1 {
      color: #5d5d5d;
      z-index: 10;
      position: relative;
      background-color: white;
    }
    .bell2 {
      color: #4d4bac;
      z-index: 3;
      position: relative;
      right: 20px;
    }
  }
  .dropdown-item-list:hover {
    background-color: #0000000d;
    border-radius: 0.3rem;
  }
}
</style>
